<template>
  <section id="Marquee">
    <ExperiencesCarousel :carouselImages="carouselImages" />
  </section>
</template>

<script>
  import ExperiencesCarousel from '@/components/global/ExperiencesCarousel'

  export default {
    name: "Marquee",
    components: {
      ExperiencesCarousel
    },
    data() {
      return {
        carouselImages: [
          {
            image: 'Experiences/Branding/mar-1.jpg',
            title: 'Marquee',
            description: 'Situated on the north side of the Stadium directly next to the main entrance of the building, this board is a memorable way to welcome your guests.'
          },
          {
            image: 'Experiences/Branding/mar-2.jpg',
            title: 'Marquee',
            description: 'Situated on the north side of the Stadium directly next to the main entrance of the building, this board is a memorable way to welcome your guests.'
          },
          {
            image: 'Experiences/Branding/mar-3.jpg',
            title: 'Marquee',
            description: 'Situated on the north side of the Stadium directly next to the main entrance of the building, this board is a memorable way to welcome your guests.'
          },
          {
            image: 'Experiences/Branding/mar.jpg',
            title: 'Marquee',
            description: 'Situated on the north side of the Stadium directly next to the main entrance of the building, this board is a memorable way to welcome your guests.'
          }
        ]
      }
    }
  }
</script>

<style lang="scss" scoped>
  
</style>